import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import sohosystems from "../images/soho-systems.png";
import studio137 from "../images/studio137.png";
import logo from "../images/deliva_logo.png";
import instagram from "../images/instagram.png";
import facebook from "../images/fb.png";
import twitter from "../images/twitter.png";
import Scrollchor from 'react-scrollchor';

const Footer = () => {

  const isSSR = typeof window === "undefined";

  return <StaticQuery query={graphql`
  {
      allWordpressWpApiMenusMenusItems(filter: {
        name:{
          eq:"main-menu"
        }
      }){
        edges{
          node{
            name
            items{
              title
              object_slug
              url
            }
          }
        }
      }

      wordpressPage(title: { eq: "Home" }) {
        acf {
            apple_store_image {
                source_url
            }
            apple_store_link
            android_store_image {
                source_url
            }
            android_store_link
            available_in
        }
      }
    }
  `} render={props => (
      <div>
      {!isSSR && (
      <footer >
            <div className="frame footer-wrapper">
              <div className="footer-top">
                <div className="footer-logo" data-aos="fade-up" >
                    <Link to="/">
                    <img className="logo" src={logo} alt="logo" />
                    </Link>
                </div>
                <div className="footer-description" data-aos="fade-up" data-aos-delay="300">
                DELIVA was developed by an international team of developers, graphic designers and innovators, from South Africa, India and Spain.
                </div>
                <div className="stores">
                    <div className="available-in" data-aos="fade-right"> Developed by: </div>
                    <a style={{visibility: 'hidden'}} href={ props.wordpressPage.acf.apple_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.apple_store_image.source_url } alt="apple store icon" /></a>
                    {/* <a href={ props.wordpressPage.acf.android_store_link } target="_blank" rel="noopener noreferrer"><img src={ props.wordpressPage.acf.android_store_image.source_url } alt="android store icon" /></a> */}
                    <a href="https://www.sohosystems.co.za" target="_blank" rel="noopener noreferrer"><img src={ sohosystems } alt="android store icon" /></a>
                    <a href="http://www.studio137.co.za" target="_blank" rel="noopener noreferrer"><img src={ studio137 } alt="android store icon" /></a>
                </div>
              </div>
              <div className="footer-bottom">
                    <div className="footer-menu">
                        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) => (
                           (item.object_slug === 'home') ? (
                            <Link to="/" key={item.title}>
                                {item.title}
                            </Link>
                            ): (
                              (item.object_slug === 'download') ? (
                              window.location.pathname === '/' ?
                              ( <Scrollchor to="#download" key={item.title}>
                                {item.title}
                              </Scrollchor> ) : (
                                <Link to="/#download">
                                  {item.title}
                               </Link>
                              ) ) : (
                                <Link className={( window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={item.object_slug} key={item.title}>
                                  {item.title}
                                </Link> 
                              )
                            )
                        ))}
                    </div>
                    <div className="footer-copyright">
                      Copyright © 2018 by DELIVA International. All Rights Reserved.
                    </div>
              </div>
            </div>
            <div className="social-icons">
              <a href="https://www.instagram.com/delivaint/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" /></a>
              <a href="https://www.facebook.com/delivaint" target="_blank" rel="noopener noreferrer"><img className="facebook" src={facebook} alt="" /></a>
              <a href="https://twitter.com/DELIVAint" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="" /></a>
            </div>
            { (window.location.pathname === '/') ? (
              <div className="scroll-dots">
                <Scrollchor to="#home"><div className="hover arrow_box">Home</div></Scrollchor>
                <Scrollchor to="#advertise"><div className="hover arrow_box">Adverise</div></Scrollchor>
                <Scrollchor to="#download"><div className="hover arrow_box">Download</div></Scrollchor>
                <Scrollchor to="#blog"><div className="hover arrow_box">Updates</div></Scrollchor>
                <Scrollchor to="#contact"><div className="hover arrow_box">Contact</div></Scrollchor>
                <Scrollchor to="#about"><div className="hover arrow_box">About</div></Scrollchor>
              </div> ) : null
             }
            

      </footer>
      )}
      </div>
  )} />
}

export default Footer
