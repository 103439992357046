import React, { useState, Component } from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import logo from "../images/deliva_logo.png"
import logoDark from "../images/deliva_logo_dark.png"
import Scrollchor from 'react-scrollchor';

const isSSR = typeof window === "undefined";

class Header extends Component {
  
  state = {
    isMenuOpened: false,
    isFixedMenuOpened: false
  };

  openMenu = () => {
    if(!this.state.isMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    this.setState( ( prevState ) => {
      return { isMenuOpened: !prevState.isMenuOpened };
    });
  }

  closeMenu = () => {
    document.body.style.overflow = 'unset';
    this.setState( () => {
      return { isMenuOpened: false, isFixedMenuOpened: false };
    });
  }

  openFixedMenu = () => {
    if(!this.state.isFixedMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    this.setState( ( prevState ) => {
      return { isFixedMenuOpened: !prevState.isFixedMenuOpened };
    });
  }
  
  componentWillUnmount() {
      document.body.style.overflow = 'unset';
  }

  render() {

  return <StaticQuery query={graphql`
  {
      allWordpressWpApiMenusMenusItems(filter: {
        name:{
          eq:"main-menu"
        }
      }){
        edges{
          node{
            name
            items{
              title
              object_slug
              url
            }
          }
        }
      }
    }
  `} render={props => (
      <div>
      {!isSSR && (
      <header className={"headermenu " + ( window.location.pathname === '/' ? 'homepage' : 'otherpages')}>
        <div className="frame header-wrapper">
          <div className="header-logo">
              <Link to="/">
                <img className="logo" src={logo} alt="logo" />
              </Link> 
              <Link to="/">
                <img className="logodark" src={logoDark} alt="logo" />
              </Link>
          </div>
          <div className={ "header-menu header-menu1 " + ( this.state.isMenuOpened ? 'menu-opened' : '')} >
            
            {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                (item.object_slug === 'home') ? (
                <Link className={( window.location.pathname === '/' ? 'active' : '')} to="/" key={item.title}>
                    {item.title}
                </Link>
                ): (
                  (item.object_slug === 'download') ? (
                    <a className="menu-download" onClick={this.closeMenu}  key={item.title}>
                      {window.location.pathname === '/' ? 
                      ( <Scrollchor to="#download">
                        {item.title}
                      </Scrollchor> ) : (
                        <Link to="/#download">
                         {item.title}
                        </Link>
                      ) }
                    </a> ) : ((item.object_slug === 'deals') ? (
                      <a href={item.url} target="_blank" key={item.title}>{item.title}</a>
                    ) : (
                    <Link className={( window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={'/' + item.object_slug} key={item.title}>
                      {item.title}
                    </Link> 
                  )
                  )
                )
            ))}
          </div>

          <div className={ "mobilebutton " +  ( window.location.pathname === '/' ? 'homepage ' : 'otherpages ') +  ( this.state.isMenuOpened ? 'menu-opened' : '')}>
            <a className="menu-btn menu-btn1" onClick={this.openMenu}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
          </div>

        </div>
      </header>
      )}

{!isSSR && (
      <header className={"headermenu headermenu-f " + ( window.location.pathname === '/' ? 'homepage' : 'otherpages')}>
        <div className="frame header-wrapper">
          <div className="header-logo">
              <Link to="/">
                <img className="logo" src={logo} alt="logo" />
              </Link> 
              <Link to="/">
                <img className="logodark" src={logoDark} alt="logo" />
              </Link>
          </div>
          <div className={ "header-menu header-menu2 " + ( this.state.isFixedMenuOpened ? 'menu-opened' : '')} >
            {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
                (item.object_slug === 'home') ? (
                <Link className={( window.location.pathname === '/' ? 'active' : '')} to="/" key={item.title}>
                    {item.title}
                </Link>
                ): (
                  (item.object_slug === 'download') ? (
                    <a className="menu-download" onClick={this.closeMenu} key={item.title}>
                      {window.location.pathname === '/' ? 
                      ( <Scrollchor to="#download" >
                        {item.title}
                      </Scrollchor> ) : (
                        <Link to="/#download">
                            {item.title}
                        </Link>
                      ) }
                    </a> ) : ((item.object_slug === 'deals') ? (
                      <a href={item.url} target="_blank" key={item.title}>{item.title}</a>
                    ) : (
                    <Link className={( window.location.pathname === '/' + item.object_slug ? 'active' : '')} to={ '/' + item.object_slug} key={item.title}>
                      {item.title}
                    </Link> 
                  )
                  )
                )
            ))}
          </div>

          <div className={ "mobilebutton " +  ( window.location.pathname === '/' ? 'homepage ' : 'otherpages ') +  ( this.state.isFixedMenuOpened ? 'menu-opened' : '')}>
            <a className="menu-btn  menu-btn2" onClick={this.openFixedMenu}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>
          </div>

        </div>
      </header>
      )}
      </div>
  )} />
  }
};

export default Header
