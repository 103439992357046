import "../css/proxima-nova.css"
import "../css/style.scss"
import "../css/responsive.scss"
import React from 'react'
import Helmet from "react-helmet"
import { withPrefix } from "gatsby" 
import Header from "../components/header"
import Footer from "../components/footer"

const DefaultTemplate = ({ children }) => (
    <div>
      <Helmet>
        <title>DELIVA Online and Offline Coupons and Deals NEAR YOU</title>
        <meta name="p:domain_verify" content="3f75c882a01931735ed94ce3b19e5b5b"/>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js" type="text/javascript" />
        <script src={withPrefix('studio137.js')} type="text/javascript" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-180454153-2"></script>
        <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-180454153-2');
        `}</script>
      </Helmet>
      <Header />
      { children }
      <Footer />
    </div>
  )
  
export default DefaultTemplate
  
   
